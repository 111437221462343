












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import G2 from '@antv/g2'
import { DataSet } from '@antv/data-set'

@Component({
})
export default class ASComponentsDashboardReportCheck extends Vue {
  tooltip: any = [{
    status: '已激活',
    color: 'background: #5cd2b8',
    count: 0
  }, {
    status: '未激活',
    color: 'background: #e8cc66',
    count: 0
  }]
  @Prop()
  id: string
  @Prop()
  status: any[]

  chart: any = {}
  mounted () {
    this.drawChart(this.dataChange(this.status))
  }
  drawChart (sourceData) {
    sourceData.map((v, index) => {
      this.tooltip[index].count = v.count
    })
    const { DataView } = DataSet
    const dv = new DataView()
    dv.source(sourceData).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent'
    })
    this.chart = new G2.Chart({
      container: this.id,
      width: 262,
      height: 230,
      padding: { top: 10, right: 30, bottom: 10, left: 10 }
    })
    this.chart.legend(false)
    this.chart.source(dv, {
      percent: {
        formatter: val => {
        val = (val * 100) + '%'
          return val
        }
      }
    })
    this.chart.coord('theta', {
      radius: 0.75,
      innerRadius: 0.6
    })
    this.chart.tooltip({
      showTitle: false,
      itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}</li>'
    })
    const interval = this.chart.intervalStack()
    .position('percent')
    .color('item', ['#5cd2b8', '#e8cc66'])
    .style({
      lineWidth: 1,
      stroke: '#fff'
    })
    this.chart.render()
  }
  typeChange (type) {
    switch (type) {
    case 'verified':
      return '已激活'
    case 'unverified':
      return '未激活'
    }
  }
  dataChange (data) {
    let newArr = []
    if (data.length) {
      data.map((item, index) => {
        newArr.push({
          'item': this.typeChange(item.status),
          'count': item.total
        })
      })
    }
    return newArr
  }
  @Watch('status')
  onStatusChanged(val: any[], oldVal: any[]) {
    // 避免render多次调用 render一次就渲染一次图表
    this.chart.destroy()
    if (val.length !== 0) {
      val.length !== 0 && this.drawChart(this.dataChange(val))
    }
  }
}
