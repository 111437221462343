












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import G2 from '@antv/g2'
import { DataSet } from '@antv/data-set'

@Component({
  props: {
    id: String,
    status: Array,
    caseTotal: Number,
    type: String
  }
})
export default class ASComponentsDashboardReportCheck extends Vue {
  tooltip: any = [{
    status: '待审核',
    color: 'background: #e8cc66',
    count: 0
  }, {
    status: '已通过',
    color: 'background: #5cd2b8', 
    count: 0
  }, {
    status: '已驳回',
    color: 'background: #ffb875',
    count: 0
  }]
  
  @Prop()
  id: string
  @Prop()
  status: any[]
  @Prop()
  caseTotal: Number
  @Prop()
  type: string

  chart: any = {}
  mounted () {
    this.drawChart(this.dataChange(this.status))
  }
  drawChart (sourceData) {
    sourceData.map((v, index) => {
      this.tooltip[index].count = v.count
    })
    const { DataView } = DataSet
    const dv = new DataView()
    dv.source(sourceData).transform({
      type: 'percent',
      field: 'count',
      dimension: 'item',
      as: 'percent'
    })
    this.chart = new G2.Chart({
      container: this.id,
      width: 262,
      height: 230,
      padding: { top: 10, right: 30, bottom: 10, left: 10 }
    })
    this.chart.legend(false)
    this.chart.source(dv, {
      percent: {
        formatter: val => {
        val = (val * 100) + '%'
        return val
        }
      }
    })
    this.chart.coord('theta', {
      radius: 0.75,
      innerRadius: 0.6
    })
    this.chart.tooltip({
      showTitle: false,
      itemTpl: '<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}</li>'
    })
    const interval = this.chart.intervalStack()
    .position('percent')
    .color('item', ['#e8cc66', '#5cd2b8', '#ffb875'])
    .style({
      lineWidth: 1,
      stroke: '#fff'
    })
    this.chart.render()
  }
  dataChange (data) {
    let newArr = []
    data.map((item, index) => {
      newArr.push({
        'item': this.typeChange(item.status),
        'count': item.total
      })
    })
    return newArr
  }
  typeChange (type) {
    switch (type) {
    case 'approved':
      return '已通过'
    case 'draft':
      return '草稿'
    case 'pending':
      return '待审核'
    case 'rejected': 
      return '已驳回'
    }
  }

  @Watch('status')
  onStatusChanged(val: any[], oldVal: any[]) {
    this.chart.destroy()
    if (val.length !== 0) {
      if (this.type === 'medicalCase') {
        val.length !== 0 && this.drawChart(this.dataChange(val))
      }
    }
  }

  @Watch('type')
  onTypeChanged(val: string, oldVal: string) {
    this.chart.destroy()
    if (val === 'medicalCase') {
      this.drawChart(this.dataChange(this.status))
     
    }
  }
}
