






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import G2 from '@antv/g2'

@Component({
})
export default class ASComponentsDashboardReportLine extends Vue {
  color:string = '#5ed1b8'

  @Prop()
  id: string
  @Prop()
  status: any[]
  @Prop()
  history: {
    series: any[]
  }
  @Prop()
  type: string
  
  chart: any = {}
  mounted () {
    this.drawChart()
  }
  drawChart () {
    this.color = this.id === 'dayAddDoctor' ? '#5ed1b8' : '#e5ce67'
    let sourceData = []
    this.history[this.type] && this.history[this.type].map((item, index) => {
      sourceData.push({
        '数量': item,
        'day': this.history.series[index]
      })
    })
    this.chart = new G2.Chart({
      container: this.id,
      width: 900,
      height: 440,
      padding: { top: 40, right: 50, bottom: 100, left: 70 }
    })
    this.chart.source(sourceData)
    this.chart.scale('value', {
      min: 0
    })
    this.chart.tooltip({
      crosshairs: {
        type: 'rect'
      }
    })
    this.chart.area().position('day*数量').shape('smooth').color(this.color)
    this.chart.line().position('day*数量').shape('smooth').color(this.color)
    this.chart.point().position('day*数量').color('#fff').size(6).shape('circle').style({
      stroke: this.color,
      lineWidth: 2
    })
    this.chart.render()
  }
  
  @Watch('history')
  onHistoryChanged(val: Object, oldVal: Object) {
    this.chart && this.chart.destroy()
    if (Object.keys(val).length !== 0) {
       this.drawChart()
    }
  }
}
