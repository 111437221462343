


























import Vue from 'vue'
import Component from 'vue-class-component'

@Component ({
  props: {
    id: String,
    iconType: String,
    name: String,
    total: Number
  }
})

export default class ASComponentsDashboardReportCount extends Vue {
  mounted () {
  }
}
